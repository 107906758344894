import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import { getEarningGiftCards,getGiftRequest} from "../helpers/EarningGiftCard/getEarningGiftCards";
import EvaluatedCard from "../components/giftCards/evaluatedCard";
import OngoingEntitlementCard from "../components/giftCards/ongoingEntitlementCard";
import PaymentCompletedCard from "../components/giftCards/paymentCompletedCard";
import WaitingForApprovalCard from "../components/giftCards/waitingForApprovalCard";
import WaitingForPaymentCard from "../components/giftCards/waitingForPaymentCard";
import RejectedCard from "./giftCards/rejectedCard";
import NotFound from "./cashCards/notFound";
const EarningGiftCard = ({earningGiftCardStatus}) => {
  const [renderData, setRenderData] = useState();
  const [userToken, setUserToken] = useState(
    window.localStorage.getItem("token")
  );

  const Data = {
  
    "data": [
      {
        "earningId": "68d97a9b-0828-430b-a0c6-7b4fd47c9f8d",
        "campaignTitle": "Uzun Siyah Erkek Şort 12201546_JPSTRICK JJORIGINAL",
        "influencerName": "melih diker",
        "invoiceId": "202015975368",
        "invoiceAmount": 26,
        "invoiceDate": "2023-12-15T00:00:00+03:00",
        "earningStatus": 1,
        "earningType": 1
      },
      {
        "earningId": "68d97a9b-0828-430b-a0c6-7b4fd47c9f8d",
        "campaignTitle": "Uzun Siyah Erkek Şort 12201546_JPSTRICK JJORIGINAL",
        "influencerName": "melih diker",
        "invoiceId": "303084575428",
        "invoiceAmount": 15.5201768,
        "invoiceDate": "2020-12-15T00:00:00+03:00",
        "earningStatus": 1,
        "earningType": 1
      },


    ],
  }




//  const getPaymentRequestData = async () => {
//     let response = await getGiftRequest(earningGiftCardStatus);
//     setRenderData(Data.data);
      
//      if (response.status) {
//       setRenderData(response.data.data);
//       setRenderData(Data.data);
//     }
    
//   };
//   useEffect(() => {
//     if(earningGiftCardStatus==="ongoingEntitlement")
//     {getData();}
//     else {
//       getPaymentRequestData();

//     }
//   }, []);


//   const getData = async () => {
//     let response = await getEarningGiftCards(earningGiftCardStatus);
//     setRenderData(Data.data);
//     if (response.status) {
//       setRenderData(response.data.data);
//        setRenderData(Data.data);
//     }
//   };

  
  const newStatus = earningGiftCardStatus

  function renderGiftCardStatusComponent(newStatus) {
    const lowerCaseStatus = newStatus?.toLowerCase();
  
    switch (lowerCaseStatus) {
      case "paymentcompleted":
        return <PaymentCompletedCard earningGiftCardStatus={earningGiftCardStatus} />;
      case "waitingforpayment":
        return <WaitingForPaymentCard earningGiftCardStatus={earningGiftCardStatus} />;
      case "waitingforapproval":
        return <WaitingForApprovalCard earningGiftCardStatus={earningGiftCardStatus} />;
      case "evaluated":
        return <EvaluatedCard earningGiftCardStatus={earningGiftCardStatus} />;
      case "ongoingentitlement":
        return <OngoingEntitlementCard earningGiftCardStatus={earningGiftCardStatus} />;
      case "rejected":
        return <RejectedCard earningGiftCardStatus={earningGiftCardStatus} />;
        
      default:
        return null; // Duruma uygun bir bileşen bulunamazsa null döndürülebilir veya hata işlemleri yapılabilir.
    }
  }
  
  

  return (
    <div>
       
    
      {!userToken ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <RingLoader className="my-5" color="#729abf" size={80} />
        </div>
      ) : !renderData ? (
        <div>
           {renderGiftCardStatusComponent(newStatus)} 
        </div>
      ) : <div style={{display:"flex",alignItems:"center",paddingTop:"100px"}}>
       <p><NotFound/></p>
        </div>
        }
    </div>
  );
};

export default EarningGiftCard;
