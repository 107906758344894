import React from 'react'

const notFound = () => {
    return (
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"10px"}}>
       <div className="card text-bg-light mb-3" style={{maxWidth: '27rem'}}>
       <div className="card-body" >
         <div style={{display: "flex", flexDirection: "row"}}>
           <div style={{display: "flex", flexDirection: "column", marginRight: "20px"}}>
             <h6 className="card-title" style={{fontSize:"12px",padding:"10px",fontSize:"13px"}}>Herhangi bir Aktif Ödemeniz Bulunmamaktadır.</h6>
             
           </div>
          
         </div>
       </div>
     </div>
     </div>
     
       )
     }
export default notFound