import {RequestConfig} from "../RequestConfig";
import {baseURL} from "../../configurations/environment"
import Axios from "axios";

  export const getReports = async (props) => {
    const {time,campaignId,impression,timezone,startDate,endDate} = props;
    if(startDate && endDate){
      var requestURL = baseURL +`/api/v1/report/getReport/${time}/${campaignId}/${impression}/${timezone}/${startDate}/${endDate}`
    }else{
    var requestURL = baseURL +`/api/v1/report/getReport/${time}/${campaignId}/${impression}/${timezone}`
;
    }
    let responseResult = {
      status: "",
      message: "",
      data: "",
    };
    await Axios.get(requestURL, RequestConfig())
      .then((response) => {
        responseResult = {
          status: true,
          message: response.message && response.message,
          data: response.data && response.data,
        };
      })
      .catch((error) => {
        responseResult = {
          status: false,
          message:
            error.response &&
            error.response?.data &&
            error.response.data.errors &&
            error.response.data.errors,
        };
      });
    return responseResult;
  };

  export const getCampaign = async (props) => {
    const {campaignId} = props;
    let requestURL = baseURL +`/api/v1/campaign/${campaignId}`
;
    let responseResult = {
      status: "",
      message: "",
      data: "",
    };
    await Axios.get(requestURL, RequestConfig())
      .then((response) => {
        responseResult = {
          status: true,
          message: response.message && response.message,
          data: response.data && response.data,
        };
      })
      .catch((error) => {
        responseResult = {
          status: false,
          message:
            error.response &&
            error.response?.data &&
            error.response.data.errors &&
            error.response.data.errors,
        };
      });
    return responseResult;
  };


  export const getReportProducts = async (props) => {
    const {time,campaignId,impression,timezone,startDate,endDate} = props;
     if (startDate && endDate) {
      
      var requestURL = baseURL +`/api/v1/report/getReportProducts/${time}/${campaignId}/${impression}/${timezone}/${startDate}/${endDate}`
    } else {
     let startDate = null
  let     endDate = null
      var requestURL = baseURL +`/api/v1/report/getReportProducts/${time}/${campaignId}/${impression}/${timezone}`
    }
;
    let responseResult = {
      status: "",
      message: "",
      data: "",
    };
    await Axios.get(requestURL, RequestConfig())
      .then((response) => {
        responseResult = {
          status: true,
          message: response.message && response.message,
          data: response.data && response.data,
        };
      })
      .catch((error) => {
        responseResult = {
          status: false,
          message:
            error.response &&
            error.response?.data &&
            error.response.data.errors &&
            error.response.data.errors,
        };
      });
    return responseResult;
  };
