import {RequestConfig} from "../RequestConfig";
import {baseURL} from "../../configurations/environment"
import Axios from "axios";

  export const getCollection = async (id) => {
    let requestURL = baseURL +`/api/v1/collection/getCollectionProducts/${id}`
; 
    let responseResult = {
      status: "",
      message: "",
      data: "",
    };
    await Axios.get(requestURL)
      .then((response) => {
        responseResult = {
          status: true,
          message: response.message && response.message,
          data: response.data && response.data,
        };
      })
      .catch((error) => {
        responseResult = {
          status: false,
          message:
            error.response &&
            error.response?.data &&
            error.response.data.errors &&
            error.response.data.errors,
        };
      });
    return responseResult;
  };

  