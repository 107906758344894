import React, { useState,useEffect} from 'react'
import Chart from  "./Chart";
import { getReports,getCampaign,getReportProducts } from '../helpers/Reports/getReports';
import { RingLoader } from 'react-spinners';
import EarningGraph from './Graphics/EarningGraph';
import ImpressionGraph from './Graphics/ImpressionGraph';
import ClickGraph from './Graphics/ClickGraph';
import ReturnGraph from './Graphics/ReturnGraph';
import CancelGraph from './Graphics/CancelGraph';
const Reports = ({reportName,timeWindow,campaignID,startDate,endDate }) => {

 
  const [renderData, setRenderData] = useState();
  const [changedData, setChangedData] = useState([{date:"0",value:"0"}]);
  const [timeZone, setTimeZone] = useState();
  const [campaignData, setCampaignData] = useState();
  const [graphData, setGraphData] = useState();
  const [weekDatas, setWeekDatas] = useState();
  const [totalGain, setTotalGain] = useState(0);
const [time, setTime] = useState("");
const [hourlyData, setHourlyData] = useState();
const [newReportName, setNewReportName] = useState("");
  const [userToken, setUserToken] = useState(window.localStorage.getItem(
    "token")
    
   
);

 
 
const [isLoading, setIsLoading] = useState(false);
useEffect(() => {
   setTime(timeWindow)
   const { timeZone} = Intl.DateTimeFormat().resolvedOptions();
    setTimeZone(timeZone)
     
  if(time && campaignID && timeZone){
   
  getData(newReportName,time,campaignID,timeZone);
  getCampaignData(campaignID);
  getReportProductsData(campaignID,time,timeZone,newReportName);
  }
}, [time]);


 

useEffect(() => {
  if(reportName==="clicks"){
    setNewReportName("clicks")
  }
  else if(reportName==="transformation"){
    setNewReportName("Impression")
  }
  else if(reportName==="cancel"){
    setNewReportName("cancel")
  }
  else if(reportName==="rebate"){
    setNewReportName("return")
  }
  else if(reportName==="benefit"){
    setNewReportName("earning")
  }
},[reportName])

const formatDateString = (dateString) => {
  const dateObject = new Date(dateString);
  const options = { day: 'numeric', month: 'short' };
  return dateObject.toLocaleDateString('tr-TR', options);
  
};

const formatHourString = (dateString) => {
  const dateObject = new Date(dateString);
  const options = { hour: 'numeric', hour12: false };
  return dateObject.toLocaleTimeString('tr-TR', options);
};


const getData = async (newReportName,time,campaignID,timeZone) => {
  
  setIsLoading(false);
  const data=   {
    campaignId: campaignID,
    time : time,
    impression: newReportName,
    timezone: encodeURIComponent(timeZone),
    startDate: startDate && startDate !== "" ? new Date(startDate).toISOString() : null,
    endDate: endDate && endDate !== "" ? new Date(endDate).toISOString() : null,
    
  }

  let response = await getReports(data);
 
  if (response.status) {
     
    setRenderData(response?.data?.data?.reports);
 
    setTotalGain(response?.data?.data?.totalEarning);

    

    setIsLoading(false);
    
    if(time==="today" || time==="yesterday"  ){
      const formattedReports = response?.data?.data?.reports.map(report => ({
        ...report,
        date: formatHourString(report.date),
        
      }));
    
      setChangedData(formattedReports);}
      else{
        const formattedReports = response?.data?.data?.reports.map(report => ({
          ...report,
          date: formatDateString(report.date),
          
        }));
      
        setChangedData(formattedReports);
      }
   
  

  }
 
};

const getCampaignData = async (campaignID) => {
  let data=   {
    campaignId: campaignID,
   
  }
  let response = await getCampaign(data);
  if(response.status){
    setCampaignData(response?.data?.data);
  }
}

    

 const reportNameRenderer = (reportName) => {
  const reportlower = reportName.toLowerCase();
  switch (reportlower) {
    case "benefit":
      return (
        <EarningGraph data={changedData} time={timeWindow} totalGain={totalGain} graphData={graphData} campaignData={campaignData}/>
      );
    case "transformation":
      return (
        <ImpressionGraph data={changedData} time={timeWindow} totalGain={totalGain} graphData={graphData} campaignData={campaignData}/>
      );
    case "clicks":
      return (
        <ClickGraph renderData={renderData} data={changedData} time={timeWindow} totalGain={totalGain} graphData={graphData} campaignData={campaignData}/>
      );
    case "rebate":
      return (
        <ReturnGraph data={changedData} time={timeWindow} totalGain={totalGain} graphData={graphData} campaignData={campaignData}/>
      );
      case "cancel":
      return (
        <CancelGraph data={changedData} time={timeWindow} totalGain={totalGain} graphData={graphData} campaignData={campaignData}/>
      );
    default:
      return null;
  }
};
 
const getReportProductsData = async (campaignID,time,timeZone,newReportName) => {
 
  let data=   {
    campaignId: campaignID,
    time : time,
    impression: newReportName,
    timezone: encodeURIComponent(timeZone),
    startDate: startDate && startDate !== "" ? new Date(startDate).toISOString() : null,
endDate: endDate && endDate !== "" ? new Date(endDate).toISOString() : null,
     
    
  }
 
 
  let response = await getReportProducts(data);
 
  if(response.status){
   
    setGraphData(response?.data?.data);
  }
}
    



// HAFTALIK ORTALAMAYI HESAPLAR
// function formatDate(dateString) {       //null check
//   var date = new Date(dateString);
//   return (date.getMonth() + 1) + "/" + date.getDate();
// }

// // Günlük ortalama değerleri hesaplayan fonksiyon
// function calculateDailyAverages(renderData) {
//   var averages = {};
//   var counts = {};
// if(renderData){
//   renderData?.forEach(function(entry) {
//     var date = formatDate(entry.date);
//     if (!averages[date]) {
//       averages[date] = 0;
//       counts[date] = 0;
//     }
//     averages[date] += entry.value;
//     counts[date]++;
//   });
// }
//   Object.keys(averages).forEach(function(date) {
//     averages[date] = averages[date] / counts[date];  //toplam mı ortalama mı
    
//   });

//   return averages;
// }

// // Günlük ortalama değerleri hesapla
// var dailyAverages = calculateDailyAverages(renderData);

// // Sonuçları konsola yazdır

// var results =[]
// Object.keys(dailyAverages).forEach(function(date) {
//   results.push({date: date, value: dailyAverages[date]})
  
// });

// const sendingData = results.slice(-7);
// ////////////////////////////////////////////////////////////////////

// ///////////////////////////////SON GÜNÜN VERİLERİNİ ALMA///////////////////////////
// if(renderData){
// var lastDay = renderData[renderData?.length - 1]?.date.split("T")[0];
// }
// // Son günün verilerini içeren yeni bir dizi oluştur
// if(renderData){
// var lastDayData = renderData?.filter(function(entry) {
//   return entry.date.startsWith(lastDay);
// });
// }
// function renderDat2(data) {
//   const renderedData = [];

//   data?.forEach(obj => {
//     const date =   obj.date
//     const value = obj.value;

//     renderedData.push({ date, value });
//   });

//   return renderedData;
// }

// // İşlenmiş verileri içeren yeni dizi
// const processedData = renderDat2(lastDayData);

// const hourlyAverages = {};
// //////////////////////////////////////////////// SAATLİK VERİLER
// processedData?.forEach(item => {
//   const date = new Date(item.date);
//   const hour = date.getUTCHours();
  
//   if (!hourlyAverages[hour]) {
//     hourlyAverages[hour] = { date, value: 0, count: 0 };
//   }
  
//   hourlyAverages[hour].value += item.value;
//   hourlyAverages[hour].count++;
// });

// const hourlyData = [];
// for (let i = 0; i < 24; i++) {
//   const date = (i < 10 ? "0" + i : i.toString());
//   const average = hourlyAverages[i] ? hourlyAverages[i].value / hourlyAverages[i].count : 0; //ortalama değilde toplam ise kaldır
//   hourlyData.push({ date, value: average });
// }




 
  return (
 
<div>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "50px" }}>
         <RingLoader color={"#1d8cf8"} loading={isLoading} size={50} />
        </div>
      ) : (
        renderData ? (
         
          <div>
          {
            reportNameRenderer(reportName)
          }
        </div>

          
        ) : (
          <RingLoader color={"#1d8cf8"} loading={isLoading} size={50} />
        )
      )}
    </div>
  )
}

export default Reports