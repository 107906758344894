import React, { useEffect, useState } from 'react';
import {getFaq} from '../../helpers/sss/sssHelper';
const Accordion = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const commissionRates = [
    { category: 'Kadın Ayakkabı', rate: '15%' },
    { category: 'Erkek Ayakkabı', rate: '15%' },
    { category: 'Çocuk Ayakkabı', rate: '15%' },
    { category: 'Kadın Giyim', rate: '15%' },
    { category: 'Kozmetik', rate: '15%' },
    // Add more categories if needed
  ];
  const accordionData = [
    {
      title: "Komisyon",
      description: (
        <div>
          <p>
            Komisyon miktarı her kampanya için ayrıdır. Kampanya detayda verilen
            bilgiye göre ciro kampanyaları için satıştan yüzdelik komisyon
            kazanabilirsiniz. Diğer kampanya türleri için ise adet miktarına göre
            tl bazında komisyon elde edersiniz. Kendi oluşturduğunuz koleksiyonlardan
            satış yapılan tutarın kdv çıkarılmış halinden %10 komisyon elde edebilirsiniz.
          </p>
          <table className="mt-4 border-collapse w-full">
            <thead>
              <tr className="border-b">
                <th className="p-2 text-left">Kategori</th>
                <th className="p-2 text-left">Komisyon Oranı</th>
              </tr>
            </thead>
            <tbody>
              {commissionRates.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{item.category}</td>
                  <td className="p-2 text-center">{item.rate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
    },  
    {
      title: 'Inclub Nedir?',
      description:
       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
      title: 'Nasıl Üye Olunur?',
      description:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, "
    },
    {
      title: 'Kurulum?',
      description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable",
    },
  ];

const commissionData=  {
      title: "Komisyon",
      description: (
        <div>
          <p>
            Komisyon miktarı her kampanya için ayrıdır. Kampanya detayda verilen
            bilgiye göre ciro kampanyaları için satıştan yüzdelik komisyon
            kazanabilirsiniz. Diğer kampanya türleri için ise adet miktarına göre
            tl bazında komisyon elde edersiniz. Kendi oluşturduğunuz koleksiyonlardan
            satış yapılan tutarın kdv çıkarılmış halinden %10 komisyon elde edebilirsiniz.
          </p>
          <table className="mt-4 border-collapse w-full">
            <thead>
              <tr className="border-b">
                <th className="p-2 text-left">Kategori</th>
                <th className="p-2 text-left">Komisyon Oranı</th>
              </tr>
            </thead>
            <tbody>
              {commissionRates.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{item.category}</td>
                  <td className="p-2 text-center">{item.rate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
    };

  const toggleAccordion = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  const initialData = [commissionData];

  const [data, setData] = useState([]);
  useEffect(() => {
    getFaq().then((res) => {
       
      setData(res?.data?.data);
    });
  }, []);

  const decodeHtmlEntities = (html) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
     
    return textarea.value;
  };
  
 
  return (
    <div>
      
    {data?.map((item, index) => (
    <div style={{fontFamily:"'Jost', sans-serif"}}>
        <h2>
          <button
            type="button"
            className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 ${
              activeAccordion === index ? 'bg-gray-100 dark:bg-gray-800' : ''
            }`}
            onClick={() => toggleAccordion(index)}
            aria-expanded={activeAccordion === index}
            aria-controls={`accordion-collapse-body-${index}`}
          >
            <span className='text-start'>{item.title}</span>
            <svg
              className={`w-3 h-3 transform ${activeAccordion === index ? '' : 'rotate-180'}`}
              fill="none"
              viewBox="0 0 10 6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l4 4 4-4"
              />
            </svg>
          </button>
        </h2>
        <div
          id={`accordion-collapse-body-${index}`}
          className={`${activeAccordion === index ? 'block' : 'hidden'}`}
        >
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
          {item.description && typeof item.description === 'string' ? (
                    <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(item.description) }} />
                ) : (
                  <>{item.description}</>
                )}
          </div>
        </div>
      </div>
    ))}
  </div>
  );
};

export default Accordion;
