import React, { useState,useEffect,PureComponent} from 'react'
import { useSearchParams } from 'react-router-dom'

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Bar ,BarChart } from 'recharts';

const ImpressionGraph = ({data,time,totalGain,graphData,campaignData}) => {

  const [searchParams] = useSearchParams();

  const [startDate, setStartDate] = useState(searchParams.get("startDate") || "");
const [endDate, setEndDate] = useState(searchParams.get("endDate") || "");

 

const formatDateString = (dateString) => {
  if(dateString === "") return "";
  const dateObject = new Date(dateString);
  const options = { day: 'numeric', month: 'short' };
  return dateObject.toLocaleDateString('tr-TR', options);
  
};

    const dataBar = [
        { id: 1, value: 2400, date:"1 Ekim" },
        { id: 2, value: 1398, date:"2 Ekim" },
        { id: 3, value: 9800 , date:"3 Ekim"},
        { id: 4, value: 3908, date:"4 Ekim" },
        { id: 5, value: 4800 , date:"5 Ekim"},
        { id: 6, value: 3800 , date:"6 Ekim"},
        { id: 7, value: 4300 , date:"7 Ekim"},  
      ];
    

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div>
          
            <div className="custom-tooltip" style={{padding:"5px",paddingBottom:"0px",paddingTop:"0x",backgroundColor:"#1C1C1CCC",color:"white",borderRadius:"15%"}}>
            <h6 className="">{`${label}`}</h6>
            <p className="label text-end">{`${payload[0].value}`} TL</p>
              </div>
            </div>
          );
        }
      
        return null;
      };
      const  timeChanger = (time) => {
        const newTime = time.toLowerCase();
    
            switch (newTime) {
                case "today":
                    return "Bugün";
                case "thismonth":
                    return "Bu ay";
                case "lastmonth":
                    return "Geçen ay";
                case "last30days":
                    return "Son 30 gün";
                case "thisweek":
                    return "Bu hafta";
                case "lastweek":
                    return "Geçen hafta";
                case "last7days":
                    return "Son 7 gün";
                case "last14days":
                    return "Son 14 gün";
                case "yesterday":
                    return "Dün";
                    default:
                      return   formatDateString(startDate) + " - " + formatDateString(endDate);
            }
        }
      
      const [intervalValue, setIntervalValue] = useState(1); // interval değeri için bir state tanımlayın
      useEffect(() => {
        const updateInterval = () => {
          const screenWidth = window.innerWidth;
          const maxLabels = data?.length;
          const responsiveLabelCount = parseInt(screenWidth / 70);
          const newIntervalValue = maxLabels > responsiveLabelCount ? Math.ceil(maxLabels / responsiveLabelCount) : 1;            
          setIntervalValue(newIntervalValue);
          if(data?.length <= 7){
            setIntervalValue(0)
          }
          else if(time==="today" || time==="yesterday")
          {
             setIntervalValue(1)
        };
        };
    
        updateInterval();  // İlk yükleme için interval güncelle
        window.addEventListener('resize', updateInterval);  // Pencere yeniden boyutlandırıldığında interval güncelle
    
        return () => {
          window.removeEventListener('resize', updateInterval);  // Cleanup
        };
      }, [data]);
        return (
          <div style={{fontFamily:"'Jost', sans-serif"}}>
              <div style={{ margin:"10px", padding:"10px"}}>
              <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px"}}>
                <div>
                <p style={{fontSize:"16px",fontWeight:"400",  color:"#000000"}}>
                    {timeChanger(time)}  
                </p>
                <p style={{fontSize:"30px",fontWeight:"540",marginTop:"5px"}}>
                {`${totalGain?.toFixed(2).split(".")[0]}.${totalGain?.toFixed(2).split(".")[1].slice(0, 2)}`} { campaignData?.campaignType === 'CiroKampanyasi' ? 'TL' : '' }
                </p>
                  <h3 style={{color:"#999999"}}>Dönüşüm</h3>
                 
                </div>
                
              
              </div>
              <ResponsiveContainer width={"100%"} height={213} aspect={375 / 220}>
              <BarChart
                      width={500}
                      height={300}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: -10,
                        bottom: 5,
                      }}
                    >
                        <Tooltip label="value"  content={<CustomTooltip 
                        
                        />
                           
                      }  
                        
                        />
                         <CartesianGrid  horizontal={true} vertical={false} />
                    <XAxis dataKey="date" type="category"  axisLine={false} tickLine={false}  stroke='black' tick={{fontWeight:"600",fontSize:"10px"}}  
                    dy={15} 
                    interval={"preserveStartEnd"}/>
              <YAxis dataKey="value" type='number' axisLine={false} tickLine={false} stroke='black'  tick={{fontWeight:"600"}} />
                      <Bar
                        dataKey="value"
                        fill="#01B3BF"
                        radius={[10, 10, 0, 0]}
                        barSize={15} // Barlar arasındaki boşluğu belirleyin
                      />
                    </BarChart>
                </ResponsiveContainer>
            </div>  
               
       
            {Array.isArray(graphData) && graphData.length > 0 ? (
        <div style={{  fontSize: "18px", marginLeft: "10px", marginTop: "15px", fontWeight: "600" }}>
          <p>En çok dönüşüm getiren ürünler</p>
        </div>
      ) : null}
      
      <div className='grid grid-cols-1'>
        {Array.isArray(graphData) && graphData?.length > 0 ? (
          graphData?.map((item, index) => (
            <div key={index}
            onClick={
              () => {
                window.open(
                  `${item?.link}`,
                  "_blank"
                );
              }
            }
            className='border-b-2 border-gray-200 m-2'
            style={{  display: "flex", marginRight: "10px", padding: "10px" }}>
              <div className='flex items-center justify-center text-semibold font-semibold'>
                {index+1}
                </div>
              <div>
                
                <img src={item?.imageLink} alt={item?.title} border="0" style={{ width: "100px", height: "100%", objectFit: "" }} />
              </div>
             
              <div className='w-full' style={{ display: "flex", flexDirection: "column", marginLeft: "12px"  }}>
                <p style={{ fontWeight: "600" }}>{item?.title}</p>
                <p>{item?.categoryName}</p>
                {/* <div style={{ border: "1px solid #ccc", backgroundColor: "#D0D1D3", padding: "4px", borderRadius: "15px", display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content" }}>
                  {item.quantity}
                </div> */}
              </div>
            </div>
          ))
        ) : (
          <p> </p>
        )}
      </div>
      
          
          </div>
        )
}

export default ImpressionGraph