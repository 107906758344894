import React, { useEffect, useRef, useState } from "react";
import NotFound from "./notFound";
import { RingLoader } from "react-spinners";
import { getPaymentRequest } from "../../helpers/EarningCash/getEarningCash";
const WaitingForBillApprovalCard = ({earningCashStatus}) => {
const [renderData, setRenderData] = useState(null);
  const divRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isPrevPage, setIsPrevPage] = useState(false);
  const [acceptLoading] = useState(false);
  const [rejectLoading] = useState(false);
  const [nextLoading] = useState(false);
  const [prevLoading] = useState(false);
  const [firstLoading] = useState(false);
  const [lastLoading] = useState(false);
  const [disablePagination] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(6);
  const [totalCards, setTotalCards] = useState(0);
const DateFixer = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const newdate = day + "/" + month + "/" + year;
  return newdate;
};
const status = earningCashStatus;
const getPaymentRequestData = async (status, page, limit) => {
  let form = {
    status: status,
    page: page,
    limit: limit,
  };
  let response = await getPaymentRequest(form);

  if (response.status) {
    const total=response.data.pagination.totalRecords;
    const totalpages=response.data.pagination.totalPages;
    setTotalCards(total);
    setTotalPages(totalpages);
    setRenderData(response.data.data);
     
    
  }
};
 
const url =window.location.href;

const handleNextPage = async () => {
  setCurrentPage(currentPage + 1);
   getPaymentRequestData("waitingForBillApproval",currentPage + 1, perPage);
    
  
};

const handlePrevPage = async () => {
  setCurrentPage(currentPage - 1);
  getPaymentRequestData("waitingForBillApproval",currentPage - 1, perPage);
};

const handleFirstPage = async () => {
  setCurrentPage(1);
  getPaymentRequestData("waitingForBillApproval",1, perPage);
};

const handleLastPage = async () => {
  setCurrentPage(totalPages);
  getPaymentRequestData("waitingForBillApproval",totalPages, perPage);
};

useEffect(() => {
  setTotalPages(Math.ceil(totalCards / perPage));
  if (currentPage < totalPages) {
    setIsNextPage(true);
  }
  if (currentPage >= totalPages) {
    setIsNextPage(false);
  }
  if (currentPage > 1) {
    setIsPrevPage(true);
  }
  if (currentPage <= 1) {
    setIsPrevPage(false);
  }
}, [totalCards, currentPage, totalPages]);




useEffect(() => {
  getPaymentRequestData("waitingForBillApproval",currentPage, perPage);
}, []);

 
  return (
    <div style={{ display: "flex", alignItems: "center",flexDirection:"column", justifyContent: "center", marginTop: "10px" }}>
     
     
    {renderData && renderData.length > 0 ? (
 renderData.map((item, index) => {
       
       return (
         <div key={index} className="card text-bg-light mb-3" style={{ maxWidth: '27rem', marginBottom: '10px' }}>
           <div className="card-body">
             <div style={{ display: "flex", flexDirection: "row" }}>
               <div style={{ display: "flex", flexDirection: "column", marginRight: "20px" }}>
                 <h6 className="card-title" style={{ fontSize: "12px" }}>Fatura Numarasi</h6>
                 <p className="card-text">{item?.invoiceCode || "Fatura ID bulunamadı."}</p>
               </div>
               <div style={{ display: "flex", flexDirection: "column", marginRight: "20px" }}>
                 <h6 className="card-title" style={{ fontSize: "12px" }}>Fatura Tarihi</h6>
                 <p className="card-text">{item.invoiceDate ? DateFixer(item.invoiceDate) : "Tarih bulunamadı."}</p>
               </div>
               <div style={{ display: "flex", flexDirection: "column" }}>
                 <h6 className="card-title" style={{ fontSize: "12px" }}>Ödeme Tutar</h6>
                 <p className="card-text" style={{color:"###2B2B38"}}>
                   {item.invoiceAmount
                     ? `${(item.invoiceAmount.toFixed(2)).split(".")[0]}.${(item.invoiceAmount.toFixed(2)).split(".")[1].slice(0, 2)} TL`
                     : "Belirtilmemiş"}
                 </p>
               </div>
             </div>
           </div>
         </div>
       );
     })
   ) : (
     <p><NotFound/></p>
   )}
<div className="flex justify-center mt-4">
      {totalCards > 6 && ( // totalRecords 6'dan fazlaysa butonları göster
         <> 
        <button
         style={{border:"none"}}
          className="flex cursor-pointer"
          onClick={handleFirstPage}
          disabled={!isPrevPage || disablePagination}
          title="İlk Sayfa"
        >
          {firstLoading ? (
            <RingLoader size={25} />
          ) : (
            <svg
            height={20}
            width={20}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={isPrevPage ? { color: "black" } : { color: "#9CA3AF" }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              <line x1="2" y1="20" x2="2" y2="4" strokeWidth={2}></line>
            </svg>
          )}
        </button>
        <button
        style={{border:"none"}}
          className="cursor-pointer border-none"
          onClick={handlePrevPage}
          disabled={!isPrevPage || disablePagination}
          title="Önceki Sayfa"
        >
          {prevLoading ? (
            <RingLoader size={25} />
          ) : (
            <svg
            height={20}
            width={20}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={isPrevPage ? { color: "black" } : { color: "#9CA3AF" }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          )}
        </button>
        <button
         style={{border:"none"}}
          className="cursor-pointer"
          onClick={handleNextPage}
          disabled={!isNextPage || disablePagination}
          title="Sonraki Sayfa"
        >
          {nextLoading ? (
            <RingLoader size={25} color="white" />
          ) : (
            <svg
            height={20}
            width={20}
              
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={isNextPage ? { color: "black" } : { color: "#9CA3AF" }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          )}
        </button>
        <button
         style={{border:"none"}}
          className="flex cursor-pointer"
          onClick={handleLastPage}
          disabled={!isNextPage || disablePagination}
          title="Son Sayfa"
        >
          {lastLoading ? (
            <RingLoader size={25} />
          ) : (
            <div>
            <svg
            height={20}
            width={20}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style={isNextPage ? { color: "black" } : { color: "#9CA3AF" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
            <line x1="22" y1="20" x2="22" y2="4" strokeWidth={2}></line>
          </svg>
          </div>
          )}
        </button>
        </>
      )}
      </div>
  
 </div>
   )
 }
export default WaitingForBillApprovalCard