import React, { useState,useEffect} from 'react'
import { useSearchParams } from 'react-router-dom'
import EarningCash from '../components/EarningCash';
import EarningGiftCard from '../components/EarningGiftCard';
import Reports from '../components/Reports';
import Products from '../components/Products';
import SSs from '../components/sss/sss';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Dot } from 'recharts';
const Main = () => {

    const [searchParams] = useSearchParams();

    const [token, setToken] = useState(searchParams.get("token"));


    const pathSegments = window.location.pathname.split('/');
    const idFromURL = pathSegments[pathSegments?.length - 1];

    


    const [earningCash, setEarningCash] = useState(searchParams.get("earningCash"));
    const [earningGiftCard, setEarningGiftCard] = useState(searchParams.get("earningGiftCard"));
    const [reportName, setReportName] = useState(searchParams.get("reportName"));
    const [timeWindow, setTimeWindow] = useState(searchParams.get("timeWindow"));
    const [campaignID, setCampaignID] = useState(searchParams.get("campaignId"));
    const [earningCashStatus, setEarningCashStatus] = useState(searchParams.get("cashStatus"));
    const [earningGiftCardStatus, setEarningGiftCardStatus] = useState(searchParams.get("giftCardStatus"));
    const [startDate, setStartDate] = useState(searchParams.get("startDate") || "");
const [endDate, setEndDate] = useState(searchParams.get("endDate") || "");
    const [productID, setProductId] = useState(idFromURL);
    const [productStatus, setProductStatus] = useState(pathSegments[1]);
    
    const [sss] = useState(pathSegments[1]);
   

    window.localStorage.setItem(
        "token",
        token
      );
      
      const data = [
        {
          name: 'Page A',
          uv: 4000,
          pv: 50,
          amt: 2400,
          date:"1 Eki"
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 100,
          amt: 2210,
          date:"2 Eki"
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 200,
          amt: 2290,
          date:"3 Eki"
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 300,
          amt: 2000,
          date:"4 Eki"
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 250,
          amt: 2181,
          date:"5 Eki"
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 200,
          amt: 2500,
          date:"6 Eki"
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"7 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"8 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"9 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"10 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"11 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"12 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"13 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"14 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"15 Eki"
        },
        {
          name: 'Page H',
          uv: 3490,
          pv: 220,
          amt: 2100,
          date:"16 Eki"
        },


      ];
      const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
          const value = payload[0].value;
      
          return (
            <div className="custom-tooltip">
              <Dot
                cx={payload[0].cx}
                cy={payload[0].cy}
                r={6}
                fill="#000000"
                stroke="#000000"
                strokeWidth={3}
              />
              <p>{`Value : ${value}`}</p>
            </div>
          );
        }
        return null;
      };
    
      const maxLabels = 5; // Görüntülenecek maksimum etiket sayısı
      const showAllLabels = data.length <= maxLabels;
      const [intervalValue, setIntervalValue] = useState(1); // interval değeri için bir state tanımlayın

      useEffect(() => {
        const screenWidth = window.innerWidth;
        const maxLabels = data?.length; // Görüntülenecek maksimum etiket sayısı
        const responsiveLabelCount = parseInt(screenWidth / 50); // 100px genişliğinde bir etiket için ortalama etiket sayısı
      
        // interval değerini güncelleyin
        const newIntervalValue = maxLabels > responsiveLabelCount ? Math.ceil(maxLabels / responsiveLabelCount) : 0;
        setIntervalValue(newIntervalValue);
      }, [data,window])


  return (
    <div>
            
     {/* <div style={{ margin:"10px", padding:"10px"}}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <div>
            <h3 style={{color:"black"}}> Kazanç</h3>
            <p style={{fontSize:"30px",fontWeight:"540"}}>2.134,34 TL</p>
          </div>
          
          <p style={{fontSize:"16px",fontWeight:"400",padding:"15px"}}>Son 7 gün</p>
        </div>
        <ResponsiveContainer width={"100%"} height={233} aspect={375 / 200}>
              <LineChart width={300} height={150} data={data}
              margin={{ top: 5, right: 30, left: -15, bottom: 10}}
                
              >
                  <CartesianGrid  horizontal={true} vertical={false} />
              <XAxis dataKey="date" type="category"  axisLine={false} tickLine={false}  stroke='black' tick={{fontWeight:"600"}}
              interval={intervalValue}
              dy={15} />
                <YAxis dataKey="value" type='number' axisLine={false} tickLine={false} stroke='black'  tick={{fontWeight:"600"}} />
                <Tooltip label="value"  content={<CustomTooltip />}  />
                 
                <Line type="monotone" dataKey="value" stroke="#01B3BF" strokeWidth={3.5}
            dot={false}   activeDot={{ r: 7, fill: '#02B3BE', stroke: 'white', strokeWidth: 4 }}
            curve="natural"
            />
                </LineChart>
                </ResponsiveContainer>
      </div>    */}
 

      {earningCash && <EarningCash earningCashStatus={earningCashStatus} />}
    {earningGiftCard && <EarningGiftCard earningGiftCardStatus={earningGiftCardStatus} />}
    {reportName && <Reports reportName={reportName} timeWindow={timeWindow} campaignID={campaignID} startDate={startDate} endDate={endDate} />}
    {productStatus=="product" && <Products productID={productID} productStatus={productStatus} />}
    {sss=="sss" && <SSs />}
    
    </div>
  )
}

export default Main