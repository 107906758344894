import React, { useState, useEffect } from "react";
import { getCollection } from "../helpers/Collection/getCollection";
import logo from "../assets/boyner.png"

const Products = ({ productID }) => {
  const [products, setProducts] = useState();
  const [allProducts, setAllProducts] = useState();
  const [collectionName, setCollectionName] = useState();
  const[userName,setUserName]=useState();
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
      document.title = collectionName ? `${collectionName} |  ${userName}  | Inclub ` : "Inclub";
    }, []);

  const getData = async () => {
    let response = await getCollection(productID);
    
    if (response.status) {
      const products = response?.data?.data.products;
      if (Array.isArray(products) && products?.length === 1) {
          // Tek bir ürün varsa direkt olarak ürünün linkine yönlendir.
          window.location.href = products[0].link;
      } else {
        setAllProducts(products);
          setProducts(products);
          setVisitCount(response?.data?.data?.linkVisitCount);
          setCollectionName(response?.data?.data.collectionName);
          setUserName(response?.data?.data.userName);

      }
  }
  };
  const [userNameInitials, setUserNameInitials] = useState();
  const [searchText, setSearchText] = useState('');
  const [showIcon, setShowIcon] = useState(true);
  const [visitCount, setVisitCount] = useState(0);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) {
      setShowIcon(false);
  const     filteredProducts = products?.filter((product) =>
        product?.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
      );
      setProducts(filteredProducts);
    } else {
      setProducts(allProducts);
      setShowIcon(true);
    }
  };

  const handleReset = () => {
    setSearchText('');
    setProducts(allProducts);
    setShowIcon(true);
  };

  useEffect(() => {
    if (userName) {
      const initials = userName.split(' ')
        .map(word => word.charAt(0))
        .join('')
        .slice(0, 2); // Limit to at most 2 letters
  
      setUserNameInitials(initials.toUpperCase());
    }
  }, [userName]);

const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: 'Boyner',
      text: 'InClub',
      url: window.location.href
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
}

function formatVisitCount(visitCount) {
  if (!visitCount || isNaN(visitCount)) {
    return "0";
  }

  if (visitCount < 1000) {
    return visitCount?.toString();
  }

  const abbreviations = ["", "K", "M", "B", "T"];
  const index = Math.floor(Math.log10(visitCount) / 3);
  const rounded = (visitCount / Math?.pow(1000, index))?.toFixed(visitCount % 1000 === 0 ? 0 : 1);
  return `${rounded}${abbreviations[index]}`;
}

 
 
 
 
    return (
      <div className="font-sans">
      {allProducts && Array.isArray(allProducts) && allProducts?.length > 0 ? (
        <div>
          <div className="header flex items-center justify-center py-3">
            <div className="header_left px-32 ">
              <img src={logo} alt="logo" className="sm:h-[2rem]" />
            </div>
           
          </div>
          <div className="banner w-full pl-3 py-[1.5rem] sm:pl-14 flex items-center justify-start bg-gray-100" style={{backgroundImage: 'linear-gradient(111.14deg, #EAECF9 30.17%, #ECFBFA 76.56%)'}}>
            <div className="user-avatar bg-[#2B2B38] text-white font-[540] text-base flex items-center justify-center rounded-full w-10 h-10 mr-4">
             {userNameInitials}
            </div>
            <span className=" text-gray-800 text-lg font-medium mr-2">{userName}</span>
           <div className="flex items-center justify-center mt-1">
            <svg xmlns="http://www.w3.org/2000/svg"  width="1.5rem" height="1.5rem" viewBox="0 0 24 24"><path fill="#2C3CC2" fill-rule="evenodd" d="M15.418 5.643a1.25 1.25 0 0 0-1.34-.555l-1.798.413a1.25 1.25 0 0 1-.56 0l-1.798-.413a1.25 1.25 0 0 0-1.34.555l-.98 1.564c-.1.16-.235.295-.395.396l-1.564.98a1.25 1.25 0 0 0-.555 1.338l.413 1.8a1.25 1.25 0 0 1 0 .559l-.413 1.799a1.25 1.25 0 0 0 .555 1.339l1.564.98c.16.1.295.235.396.395l.98 1.564c.282.451.82.674 1.339.555l1.798-.413a1.25 1.25 0 0 1 .56 0l1.799.413a1.25 1.25 0 0 0 1.339-.555l.98-1.564c.1-.16.235-.295.395-.395l1.565-.98a1.25 1.25 0 0 0 .554-1.34L18.5 12.28a1.25 1.25 0 0 1 0-.56l.413-1.799a1.25 1.25 0 0 0-.554-1.339l-1.565-.98a1.25 1.25 0 0 1-.395-.395zm-.503 4.127a.5.5 0 0 0-.86-.509l-2.615 4.426l-1.579-1.512a.5.5 0 1 0-.691.722l2.034 1.949a.5.5 0 0 0 .776-.107z" clip-rule="evenodd"/></svg>
              </div>
          </div>
          <div className="bg-white mt-[-0.5rem] h-5 rounded-tl-xl rounded-tr-xl ">
            </div>
          <div className="sm:px-10 flex items-center justify-between mt-[-0.8rem] z-10  px-3 py-2 sm:py-0 sticky top-0 backdrop-filter bg-white/75 backdrop-blur-lg bg-opacity-30 ">
         <div className="sm:px-12 flex flex-col ">
         <span className="text-gray-800 text-md  font-medium  ">{collectionName}</span>
         <div className="flex items-center justify-start space-x-3">
  <span className="text-[#999999] text-xs font-medium">
    {allProducts?.length > 0 ? `${allProducts?.length} Ürün` : ""}
  </span>
  <div className="h-[0.3rem] w-[0.3rem] rounded-full bg-gray-400"></div>
  <div className="flex items-center justify-center">
    <span className="text-[#999999] text-xs font-medium">
      {formatVisitCount(visitCount)}
    </span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="15" height="15" className="ml-1  ">
      <path fill="#999999" d="M243.66 126.38c-.34-.76-8.52-18.89-26.83-37.2C199.87 72.22 170.7 52 128 52S56.13 72.22 39.17 89.18c-18.31 18.31-26.49 36.44-26.83 37.2a4.08 4.08 0 0 0 0 3.25c.34.77 8.52 18.89 26.83 37.2c17 17 46.14 37.17 88.83 37.17s71.87-20.21 88.83-37.17c18.31-18.31 26.49-36.43 26.83-37.2a4.08 4.08 0 0 0 0-3.25m-32.7 35c-23.07 23-51 34.62-83 34.62s-59.89-11.65-83-34.62A135.71 135.71 0 0 1 20.44 128A135.69 135.69 0 0 1 45 94.62C68.11 71.65 96 60 128 60s59.89 11.65 83 34.62A135.79 135.79 0 0 1 235.56 128A135.71 135.71 0 0 1 211 161.38ZM128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44m0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36" />
    </svg>
  </div>
</div>

        
         </div>
         <div className="search-bar p-2  relative hidden mr-10 sm:block">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`absolute top-4 left-4 w-6 h-6 text-gray-800 ${showIcon ? 'block' : 'hidden'}`}>
        
          <path fill="#2B2B38" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14" />
        </svg>  
        
      </div>
      <div className={`${!showIcon ? 'transition-width duration-150 pl-5 w-full  ' : ' transition-width duration-150 w-full'}`}>
      <input
       
        value={searchText}
        onChange={handleInputChange}
        style={{ '--placeholder-color': '#515151', '--placeholder-font-weight': 400, '--placeholder-font-size': '0.9rem',fontWeight:"400"}}
        placeholder={allProducts?.length > 0 ? ` ${allProducts?.length} ürün içerisinde ara` : "Ara"}
        className={`pl-8 py-2 pb-2 pr-2   bg-[#F8F6F7]  rounded-full  font-medium w-full `} 
      />
      </div>
       
    </div>
         <div onClick={handleShare}
          className="sm:hidden user-avatar bg-[#F8F6F7] text-white font-bold text-lg flex items-center justify-center rounded-full w-10 h-10">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
	<path fill="none" stroke="#2B2B38" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m15 5l-3-3m0 0L9 5m3-3v12M6 9H4v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-2" />
</svg>
            

    </div>
         </div>
         <div className="sm:hidden   search-bar px-3 py-2 relative">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`absolute top-4 left-6 w-6 h-6 text-gray-800 ${showIcon ? 'block' : 'hidden'}`}>
          <path fill="#2B2B38" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14" />
        </svg>  
        
      </div>
      <div className={`${!showIcon ? 'transition-width duration-150 pl-5 w-full  ' : ' transition-width duration-150 w-full'}`}>
      <input
        type="text"
        value={searchText}
        onChange={handleInputChange}
        placeholder={allProducts?.length > 0 ? ` ${allProducts?.length} ürün içerisinde Ara` : "Ara"}
        className={`pl-8 pt-2 pb-2 pr-2   bg-[#F8F6F7]  rounded-full text-[#515151] font-medium w-full `}
      />
      </div>
      {!showIcon && (
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
         
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-5 h-5 text-[#2B2B38] cursor-pointer" onClick={handleReset}>
	<path fill="none" stroke="#2B2B38" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M328 112L184 256l144 144" />
  
</svg>
        </div>
      )}
    </div>
          <div className="grid grid-cols-2 md:grid-cols-4 sm:px-10  mt-[-0.8rem]   gap-0 md:gap-4  rounded-tl-lg rounded-tr-lg">
            {products && Array.isArray(products) && products?.length > 0 && products?.map((product) => (
              <a
                key={product?.id}
                href={product?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="product-item flex flex-col p-4"
              >
                <img src={product?.image} alt="Product" className="rounded-lg overflow-hidden object-cover w-full" />
                <div className="flex flex-col w-full text-gray-800 font-medium mt-2 text-base mb-4">
                  <p className="truncate">{product?.name}</p>
                </div>
                <span className="text-gray-800 text-lg font-bold self-end">{`${product?.price} TL`}</span>
              </a>
            ))}
          </div>
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </div>
    );
  };
export default Products;
