import React from "react";
import union from "../../assets/union.svg";
const evaluatedCard = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <div className="card text-bg-white mb-3" style={{ maxWidth: "19rem" }}>
        <div className="card-header">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
              }}
            >
              <h6 className="card-title" style={{ fontSize: "12px" }}>
              Şubat Ayı Toplam Kazanılan Komisyon Tutarı
              </h6>
              <p className="card-text" style={{color:"#999999"}}>1.984,80 TL</p>
              <div style={{display:"flex"}}>
              <img src={union} style={{marginBottom:"2rem",marginRight:"0.3rem"}}/>
              <p style={{lineHeight:"16px",fontSize:"12px",fontWeight:"450"}}>iptal iade süresi bekleniyor. Gördüğünüz tutarda değişiklik olabilir</p>
              </div>
            </div>
            
            
          </div>
         
        </div>
         
      </div>
    </div>
  );
};

export default evaluatedCard;
