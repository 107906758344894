import {RequestConfig} from "../RequestConfig";
import {baseURL} from "../../configurations/environment"
import Axios from "axios";


  export const getEarningGiftCards = async (props) => {
    const { status, page, limit } = props;
    let requestURL = baseURL + `/api/v1/Earning/getearningInfluencer/${status}/GiftCard/${page}/${limit}`
;
    let responseResult = {
      status: "",
      message: "",
      data: "",
    };
    await Axios.get(requestURL, RequestConfig())
      .then((response) => {
        responseResult = {
          status: true,
          message: response.message && response.message,
          data: response.data && response.data,
        };
      })
      .catch((error) => {
        responseResult = {
          status: false,
          message:
            error.response &&
            error.response?.data &&
            error.response.data.errors &&
            error.response.data.errors,
        };
      });
    return responseResult;
  };

  export const getGiftRequest = async (props) => {
    const { status, page, limit } = props;
    let requestURL = baseURL + `/api/v1/PaymentRequest/getall/GiftCard/${status}/${page}/${limit}`
;
    let responseResult = {
      status: "",
      message: "",
      data: "",
    };
    await Axios.get(requestURL, RequestConfig())
      .then((response) => {
        responseResult = {
          status: true,
          message: response.message && response.message,
          data: response.data && response.data,
        };
      })
      .catch((error) => {
        responseResult = {
          status: false,
          message:
            error.response &&
            error.response?.data &&
            error.response.data.errors &&
            error.response.data.errors,
        };
      });
    return responseResult;
  };
