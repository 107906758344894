import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import { getEarningCash,getPaymentRequest } from "../helpers/EarningCash/getEarningCash";
import PaymentCompletedCard from "./cashCards/paymentCompletedCard";
import WaitingForPaymentCard from "./cashCards/waitingForPaymentCard";
import WaitingForBillApprovalCard from "./cashCards/waitingForBillApprovalCard";
import WaitingForBillCard from "./cashCards/waitingForBillCard";
import EvaluatedCard from "./cashCards/evaluatedCard";
import OngoingEntitlementCard from "./cashCards/ongoingEntitlementCard";
import RejectedCard from "./cashCards/rejectedCard";
import NotFound from "./cashCards/notFound";
const EarningCash = ({earningCashStatus}) => {
  const [renderData, setRenderData] = useState(null);

  const [userToken, setUserToken] = useState(
    window.localStorage.getItem("token")
  );

  // useEffect(() => {
  //   if(earningCashStatus==="ongoingEntitlement")
  //   {getData();}
  //   else {
  //     getPaymentRequestData();

  //   }
  // }, []);
 const Data = {
  
    "data": [
      {
        "earningId": "68d97a9b-0828-430b-a0c6-7b4fd47c9f8d",
        "campaignTitle": "Uzun Siyah Erkek Şort 12201546_JPSTRICK JJORIGINAL",
        "influencerName": "melih diker",
        "invoiceId": "202015975368",
        "invoiceAmount": 26,
        "invoiceDate": "2020-12-15T00:00:00+03:00",
        "earningStatus": 1,
        "earningType": 1
      },
      {
        "earningId": "68d97a9b-0828-430b-a0c6-7b4fd47c9f8d",
        "campaignTitle": "Uzun Siyah Erkek Şort 12201546_JPSTRICK JJORIGINAL",
        "influencerName": "melih diker",
        "invoiceId": "303084575428",
        "invoiceAmount": 15.5201768,
        "invoiceDate": "2020-12-15T00:00:00+03:00",
        "earningStatus": 1,
        "earningType": 1
      },


    ],
  }

//  const getPaymentRequestData = async () => {
//     let response = await getPaymentRequest(earningCashStatus);
//     setRenderData(Data.data);
//      if (response.status) {
       
//       setRenderData(response.data.data);
//       setRenderData(Data.data);
//     }
//   };



//   const getData = async () => {
//     let response = await getEarningCash(earningCashStatus);
//  setRenderData(Data.data);
//     if (response.status) {
//       console.log(response.data.data);
//       setRenderData(response.data.data);
//        setRenderData(Data.data);
//     }
//   };
 
  const newStatus = earningCashStatus
 

  function renderStatusComponent(newStatus) {
    const lowerCaseStatus = newStatus.toLowerCase();
  
    switch (lowerCaseStatus) {
      case "paymentcompleted":
        return <PaymentCompletedCard earningCashStatus={earningCashStatus} />;
      case "waitingforpayment":
        return <WaitingForPaymentCard earningCashStatus={earningCashStatus} />;
      case "waitingforbillapproval":
        return <WaitingForBillApprovalCard earningCashStatus={earningCashStatus} />;
      case "waitingforbill":
        return <WaitingForBillCard earningCashStatus={earningCashStatus} />;
      case "evaluated":
        return <EvaluatedCard earningCashStatus={earningCashStatus} />;
      case "ongoingentitlement":
        return <OngoingEntitlementCard earningCashStatus={earningCashStatus} />;
        case "rejected":
          return <RejectedCard earningCashStatus={earningCashStatus} />;
      default:
        return null; // Duruma uygun bir bileşen bulunamazsa null döndürülebilir veya hata işlemleri yapılabilir.
    }
  }
  
  
  // <PaymentCompletedCard/>
  // <WaitingForPaymentCard/>
  // <WaitingForBillApprovalCard/>
  // <WaitingForBillCard/>
  // <EvaluatedCard/>
  // <OngoingEntitlementCard/>


  return (
    <div>
       
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
       
    </div>
      
      {!userToken ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <RingLoader className="my-5" color="#729abf" size={80} />
        </div>
      ) : !renderData ? (
        <div>
            {renderStatusComponent(newStatus)}  
        </div>
      ) : <div style={{display:"flex",alignItems:"center",justifyContent:"center",paddingTop:"100px"}}>
      <p><NotFound/></p>
      </div>}
    </div>
  );
};

export default EarningCash;
